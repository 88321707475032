import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectAsync } from "../hooks/async-effect";
import { PasswordErrors } from "../hooks/validation";
import { AuthResponse } from "../models/AuthResponse";
import { ConfirmForgotPasswordRequest } from "../models/ConfirmForgotPasswordRequest";
import { MFALoginResponse } from "../models/MFALoginResponse";
import {envDir, headers} from "../services/config";
import { postApiUsersConfirmForgotPassword, postApiUsersLogin, postApiUsersResetPassword, postApiUsersSignUp, postApiUsersVerifyCode } from "../services/user";
import { useAppDispatch } from "../hooks/redux-hook";
import { loadSettings } from "../redux/userSettings";
import { dbToUserSettings } from "../services/utils";
import moment from "moment";
import { showSnackbar } from "../redux/snackbar";
import { datadogRum } from '@datadog/browser-rum';

declare global {
    interface Window {
        pendo: any
    }
}

export enum ViewPerspective {
    DEFAULT,
    SUPERADMIN,
    USER
}

type AuthenticationContext = ReturnType<typeof useCreateAuthService>
const AuthenticationContext = createContext<AuthenticationContext>(null!);

export function useAuthService() {
    return useContext(AuthenticationContext);
}

function useCreateAuthService() {
    const [loginInfo, setLoginInfo] = useState<AuthResponse>();
    const [loadingLoginInfo, setLoadingLoginInfo] = useState<boolean>(true);
    const [inactiveTime, setInactiveTime] = useState(0);
    const nav = useNavigate();
    const [viewPerspective, setViewPerspective] = useState<ViewPerspective>();
    const dispatch = useAppDispatch();
    const requiredPassChangeLength = 90;

    async function login(regInfo: { email: string, password: string }): Promise<PasswordErrors> {
        try {
            localStorage.removeItem("impersonate");
            const login = await postApiUsersLogin(regInfo) as MFALoginResponse;
            nav("/two-factor-auth", { state: regInfo.email });
            const loginInfoAndEmail = { ...login, email: regInfo.email };
            sessionStorage.setItem("userFactor", JSON.stringify(loginInfoAndEmail));
            localStorage.removeItem("impersonate");
            localStorage.removeItem("inactiveTimeOutsidePlatform");
            return PasswordErrors.SUCCESS
        } catch (e) {
            console.log(e)
            return PasswordErrors.INVALID;
        }
    }

    const verifyMultiFactorAuthCode = async (code: string, countHandler: () =>void, count: number) => {

        const verifyPasswordAge = (passwordAge: number, passwordVerifiedCallback: () => void) => {
            if (passwordAge! >= requiredPassChangeLength) {
                nav("/change-password-schedule");
                localStorage.setItem("passwordAge", passwordAge?.toString()!);
            } else {
                const remainingDays = requiredPassChangeLength - passwordAge!;
                if (remainingDays <= 7) {
                    sessionStorage.setItem("passwordChangeRequired", "true");
                }
                passwordVerifiedCallback();
            }
        }

        const superAdminLogin = () => {
            setSuperAdminPerspective();
            nav("/adminchoice");
        }

        try {
            const firstFactorResult = sessionStorage.getItem("userFactor");
            const twofactorresult = await postApiUsersVerifyCode({ ...JSON.parse(firstFactorResult!), UserCode: code });
            if(twofactorresult?.Session !== undefined){
                countHandler()
                const currentUserFactor = {
                    ...JSON.parse(firstFactorResult!),
                    Session: twofactorresult?.Session
                }
                sessionStorage.setItem("userFactor", JSON.stringify(currentUserFactor));
                return false;
            }
            setLoginInfo(twofactorresult);
            localStorage.setItem("expireTime", JSON.stringify(moment().add(60, "m")));
            if (twofactorresult.user?.role === "SUPER") {
                verifyPasswordAge(twofactorresult.user.passwordResetAge!, superAdminLogin);
            } else if (twofactorresult.user?.role === "AM") {
                verifyPasswordAge(twofactorresult.user.passwordResetAge!, () => nav("/adminchoice"));
            } else {
                verifyPasswordAge(twofactorresult.user?.passwordResetAge!, () => setUserPerspective());
            }
            return true;
        } catch (e) {
            if(count === 3) {
                countHandler();
            }
            return false;
        }
    }

    const signUpAccount = (userInfo: { fullName: string, password: string, email: string, code: string }) =>
        postApiUsersSignUp({
            name: userInfo.fullName, password: userInfo.password,
            email: userInfo.email, confirmationCode: userInfo.code
        })
            .then(() => nav("/two-factor-info"))
            .catch(error => dispatch(showSnackbar({ message: error.message, type: "error" })));

    const resetPassword = async (email: string) => {
        try {
            await postApiUsersResetPassword({ email });
            nav("/forgot-password-confirmation");
        } catch (e) {
            console.log(e);
        }
    }

    const confirmResetPassword = async (forgotPasswordInfo: ConfirmForgotPasswordRequest) => {
        try {
            await postApiUsersConfirmForgotPassword(forgotPasswordInfo);
            nav("/login");
        } catch (e) {
            console.log(e)
        }
    }

    const verifyUserRole = (role: "SUPER" | "PM" | "AM" | "ADMIN" | "USER" | "ANONYMIZE" | "REDACTION" | "VIEW") => {
        if (loginInfo?.user?.role === role) return true;
    }

    const hasUserRoleAccess = () => loginInfo?.user?.role === "SUPER" || loginInfo?.user?.role === "PM"
        || loginInfo?.user?.role === "ADMIN" || loginInfo?.user?.role === "USER" || loginInfo?.user?.role === "REDACTION" ||
        loginInfo?.user?.role === "ANONYMIZE" || (loginInfo?.user?.role === "AM" && loginInfo.tenant?.user?.roles?.some(role =>
            role==="ADMIN" || role==="PM"  || role==="REDACTION" || role==="ANONYMIZE" || role==="USER")) || false;
    //the false avoids an error because the some have a possibility of returning undefined and this removes the error

    const hasPMRoleAccess = () => loginInfo?.user?.role === "SUPER" || loginInfo?.user?.role === "PM"
        || loginInfo?.user?.role === "ADMIN" || loginInfo?.tenant?.user?.roles?.some(role =>
            role==="ADMIN" || role==="PM" ) || false

    const hasAdminRoleAccess = () => loginInfo?.user?.role === "SUPER" || loginInfo?.user?.role === "ADMIN" ||
        loginInfo?.tenant?.user?.roles?.some((role => role==="ADMIN" )) || false

    const hasDocsAccess = () => (loginInfo?.tenant?.accessToDocs && hasUserRoleAccess())

    const setUserPerspective = () => {
        nav("/app/user/landing-page");
        setViewPerspective(ViewPerspective.USER);
    }

    const setSuperAdminPerspective = () => {
        nav("/app/admin/accounts");
        setViewPerspective(ViewPerspective.SUPERADMIN);
    }

    const logout = () => {
        console.log('logged out')
        localStorage.removeItem("userPayload");
        setLoginInfo(undefined);
        nav("/login");
    }

    const changeUserName = (name: string) => {
        const prevUser = loginInfo?.tenant?.user;
        setLoginInfo({
            ...loginInfo,
            tenant: {
                ...loginInfo?.tenant,
                user: {
                    ...prevUser,
                    name,
                    email: prevUser!.email,
                    color: prevUser!.color,
                    timezone: prevUser!.timezone,
                    dateFormat: prevUser!.dateFormat,
                    timeFormat: prevUser!.timeFormat,
                    emailNotifications: prevUser!.emailNotifications,
                    inAppNotifications: prevUser!.inAppNotifications,
                    emailTaskUpdates: prevUser!.emailTaskUpdates,
                    emailMentions: prevUser!.emailMentions,
                    inAppTaskUpdates: prevUser!.inAppTaskUpdates,
                    roles: prevUser!.roles,
                    inAppMentions: prevUser!.inAppMentions,
                    emailNotificationsStartTime: prevUser!.emailNotificationsStartTime,
                    emailNotificationsEndTime: prevUser!.emailNotificationsEndTime,
                    id: prevUser!.id
                }
            }
        })
    }

    useEffectAsync(async () => {
        const impersonate = localStorage.getItem("impersonate");
        if (loginInfo === null) {
            setLoadingLoginInfo(false);
            return;
        }
        if (loginInfo) {
            console.log("Logged in with role:", loginInfo.tenant?.user?.roles)
            headers['Authorization'] = `Bearer ${loginInfo.cognito?.AccessToken}`;
            headers['X-Tenant'] = loginInfo.tenant?.schema || "";
            headers["x-impersonate"] = impersonate ?? "";
            localStorage.setItem("userPayload", JSON.stringify(loginInfo));
            if (loginInfo.tenant?.user) {
                dispatch(loadSettings(dbToUserSettings(loginInfo)));
            } else {
                dispatch(loadSettings({}));
            }
            const pendo = window.pendo;
            pendo.initialize({
                visitor: {
                    id: loginInfo.tenant?.user?.id,
                    email: loginInfo.user?.email,
                    full_name: loginInfo.tenant?.user?.name
                },
                account: {
                    id: loginInfo.tenant?.clientId,
                    name: loginInfo.tenant?.name
                }
            })
            datadogRum.init({
                applicationId: '83463f4e-0fcb-465f-98ab-6a2d33104be9',
                clientToken: 'pub31dd0ee502b60ea265d5f9f2880f011b',
                // `site` refers to the Datadog site parameter of your organization
                // see https://docs.datadoghq.com/getting_started/site/
                site: 'datadoghq.com',
                service: 'node-app',
                env: envDir,
                // Specify a version number to identify the deployed version of your application in Datadog
                // version: '1.0.0',
                allowedTracingUrls: [(url) => url.startsWith("https://app-dev.rlsciences.com"), (url) => url.startsWith("https://app-qa.rlsciences.com"),
                    (url) => url.startsWith("https://app-cqa.rlsciences.com"), (url) => url.startsWith("https://app.rlsciences.com"), /https:\/\/.*\.rlsciences\.com/],
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
            });
            if (loginInfo.user) {
                datadogRum.setUser({
                    id: loginInfo.user.id.toString(),
                    name: loginInfo.user.name,
                    email: loginInfo.user.email,
                    tenantName: loginInfo.tenant?.name
                })
            }
        } else {
            delete headers['Authorization'];
            localStorage.removeItem("userPayload");
        }
        setLoadingLoginInfo(false);
    }, [loginInfo])

    return useMemo(() => ({
        login,
        verifyMultiFactorAuthCode,
        loginInfo,
        signUpAccount,
        setLoginInfo,
        resetPassword,
        confirmResetPassword,
        verifyUserRole,
        setUserPerspective,
        setSuperAdminPerspective,
        viewPerspective,
        logout,
        changeUserName,
        loadingLoginInfo,
        setLoadingLoginInfo,
        hasUserRoleAccess,
        hasPMRoleAccess,
        hasAdminRoleAccess,
        hasDocsAccess,
        inactiveTime,
        setInactiveTime
    }), [loginInfo, viewPerspective, loadingLoginInfo, inactiveTime])
}

export function AuthenticationContextProvider(props: { children: JSX.Element }) {
    const authService = useCreateAuthService();
    return <AuthenticationContext.Provider value={authService}>
        {props.children}
    </AuthenticationContext.Provider>
}
