import React, {useState, useEffect, useContext, useRef} from 'react';
import RedactionSearchResultGroup from './RedactionSearchResultGroup';
import './RedactionSearchResults.scss';
import classNames from 'classnames';
import {Virtuoso} from 'react-virtuoso';
import AnnotationFilterHelpers from '../AnnotationFilterHelpers';
import SearchProgressBar from './SearchProgressBar';
import SearchStatus from "./SearchStatus";
import WebViewerContext from "../../../contexts/webviewer-context";
import {BasicAnnotationFilter} from "../BasicAnnotationFilter";


function RedactionSearchResults(props) {
    const {
        redactionSearchResults,
        searchStatus,
        onCancelSearch,
        isProcessingRedactionResults,
        markSelectedResultsForRedaction,
        deleteSelectedResults
    } = props;

    const {instance} = useContext(WebViewerContext);
    const [filteredSearchResultPageMap, setFilteredSearchResultPageMap] = useState({});
    const [selectedSearchResultIndexesMap, setSelectedSearchResultIndexesMap] = useState({});
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [filterPageRange, setFilterPageRange] = React.useState(undefined);
    const [searchPhrase, setSearchPhrase] = React.useState(undefined)
    const [category, setCategory] = React.useState(undefined)
    const refid = useRef(null);

    function filterPageRangeOnChange(newPageRange) {
        //We need to clone the value so that React recognizes it as a change and re-renders.
        const verifiedPageRange = newPageRange === undefined ? undefined : [...newPageRange];
        setFilterPageRange(verifiedPageRange);
    }

    function searchPhraseOnChange(newSearchPhrase) {
        setSearchPhrase(newSearchPhrase);
    }

    function categoryOnChange(newCategory) {
        setCategory(newCategory);
    }

    const scrollIntoViewByIndex = (index) => {
        if (index !== -1) {
            const el = document.querySelector('#search-result-' + index);
            if (el) {
                el.scrollIntoView({behavior: 'smooth'});
            }
        }
    }

    useEffect(() => {
        const onKeyDownHandler = (event) => {
            //TODO this caused problems when you pressed delete in other contexts
            //Delete the active search result when you press delete
            // if (event.key === 'Delete' || event.key === 'Backspace') {
            //   if (core.getActiveSearchResult() && refid.current.redactionSearchResults && refid.current.redactionSearchResults.length > 0) {
            //     deleteSelectedResults(refid.current.redactionSearchResults, [core.getActiveSearchResult()])
            //   }
            // }
            //Scroll using the arrow keys
            if (event.key === 'ArrowLeft' || event.key === 'ArrowUp' || event.key === 'ArrowRight' || event.key === 'ArrowDown') {
                event.preventDefault();
                const goUp = event.key === 'ArrowLeft' || event.key === 'ArrowUp'
                const results = refid.current.redactionSearchResults
                const activeResultIndex = results.indexOf(instance.Core.documentViewer.getActiveSearchResult())
                if (activeResultIndex !== undefined && activeResultIndex !== -1) {
                    if (goUp && activeResultIndex !== 0) {
                        instance.Core.documentViewer.setActiveSearchResult(results[activeResultIndex - 1])
                        scrollIntoViewByIndex(activeResultIndex - 1)
                    } else if (!goUp && activeResultIndex !== results.length - 1) {
                        instance.Core.documentViewer.setActiveSearchResult(results[activeResultIndex + 1])
                        scrollIntoViewByIndex(activeResultIndex + 1)
                    }
                }
            }
        };

        // add listener
        window.addEventListener('keydown', onKeyDownHandler, {
            capture: true,
            passive: false
        })
        // prevent memory leak
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
        }
    }, []);

    useEffect(() => {
        refid.current = {redactionSearchResults};
    }, [redactionSearchResults])


    useEffect(() => {
        AnnotationFilterHelpers.applyFilters(redactionSearchResults, searchPhrase, filterPageRange, category)
        const redactionSearchResultPageMap = {};
        redactionSearchResults.forEach((result, index) => {
            const pageNumber = result.pageNum;
            result.index = index;
            if (result.shouldHide) {
                return;
            }
            if (redactionSearchResultPageMap[pageNumber] === undefined) {
                redactionSearchResultPageMap[pageNumber] = [result];
            } else {
                redactionSearchResultPageMap[pageNumber] = [...redactionSearchResultPageMap[pageNumber], result];
            }
        });

        setFilteredSearchResultPageMap(redactionSearchResultPageMap);
    }, [redactionSearchResults, searchPhrase, filterPageRange, category]);

    useEffect(() => {
        const selectedIndexesMap = {};
        redactionSearchResults.forEach((index) => {
            selectedIndexesMap[index] = false;
        });
        setSelectedSearchResultIndexesMap(selectedIndexesMap);
    }, [redactionSearchResults]);


    useEffect(() => {
        const selectedIndexes = redactionSearchResults.filter((value, index) => {
            return selectedSearchResultIndexesMap[index];
        });

        setSelectedIndexes(selectedIndexes);
    }, [selectedSearchResultIndexesMap]);


    const renderSearchResults = () => {
        const resultGroupPageNumbers = Object.keys(filteredSearchResultPageMap);

        if (resultGroupPageNumbers.length > 0) {
            return (
                <Virtuoso
                    data={resultGroupPageNumbers}
                    itemContent={(index, pageNumber) => {
                        return (
                            <RedactionSearchResultGroup
                                key={index}
                                pageNumber={pageNumber}
                                searchResults={filteredSearchResultPageMap[pageNumber]}
                                selectedSearchResultIndexes={selectedSearchResultIndexesMap}
                                setSelectedSearchResultIndexes={setSelectedSearchResultIndexesMap}
                            />);
                    }}
                />);
        }
    };

    const renderStartSearch = () => (
        <div aria-label='start search'>
        </div>
    );

    const noResults = (
        <div aria-label='no results'>
            no results
        </div>
    );

    const renderSearchInProgress = () => (
        <div>
            //TODO spinner
            {/*<Spinner height="25px" width="25px" />*/}
        </div>
    );

    const onCancelHandler = () => {
        setFilteredSearchResultPageMap({});
        onCancelSearch(true);
    };

    const selectAllResults = () => {
        const searchResultIndexMap = {};
        redactionSearchResults.forEach((value, index) => {
            searchResultIndexMap[index] = selectedSearchResultIndexesMap[index]
            if (!value.shouldHide) {
                searchResultIndexMap[index] = true;
            }
        });
        setSelectedSearchResultIndexesMap(searchResultIndexMap);
    };

    const unselectAllResults = () => {
        const searchResultIndexMap = {};
        redactionSearchResults.forEach((value, index) => {
            searchResultIndexMap[index] = selectedSearchResultIndexesMap[index]
            if (!value.shouldHide) {
                searchResultIndexMap[index] = false;
            }
        });
        setSelectedSearchResultIndexesMap(searchResultIndexMap);
    };

    const onMarkAllForRedaction = () => {
        instance.Core.documentViewer.trigger('processStarted')

        //Set a timeout to get the loading modal to show up.
        setTimeout(() => {
            handleMarkForRedaction()
        }, 20000)
    };

    const handleMarkForRedaction = () => {
        const markPromise = new Promise((resolve) => {
            onCancelSearch(false);
            markSelectedResultsForRedaction(selectedIndexes, instance);
            deleteSelectedResults(redactionSearchResults, selectedIndexes, false);
            instance.Core.documentViewer.trigger('processEnded', 'mark')
            resolve();
        });

        markPromise.then(() => {
            instance.Core.documentViewer.trigger('processEnded', 'mark')
        }).catch((reason) => {
            instance.Core.documentViewer.trigger('processEnded', 'markError')
        });
    }

    const onDeleteSelectedResults = () => {
        deleteSelectedResults(redactionSearchResults, selectedIndexes, true);
    }

    const isEmptyList = redactionSearchResults.length === 0;

    const resultsContainerClass = classNames('redaction-search-results-container', {emptyList: isEmptyList});
    const deleteAllSelectedClass = classNames('delete-all-selected', {disabled: selectedIndexes.length === 0});
    const markAllForRedactionButtonClass = classNames('mark-all-selected', {disabled: selectedIndexes.length === 0});
    const shouldShowResultsCounterOptions = (searchStatus === SearchStatus['SEARCH_DONE'] && !isProcessingRedactionResults) || searchStatus === SearchStatus['SEARCH_NOT_INITIATED'];

    return (
        <>
            <div className="redaction-search-panel-controls-top">
                <button
                    onClick={onCancelHandler}
                    aria-label='cancel'
                    className="cancel"
                >
                    Cancel
                </button>
                <button
                    disabled={selectedIndexes.length === 0}
                    aria-label='delete'
                    className={deleteAllSelectedClass}
                    onClick={onDeleteSelectedResults}
                >
                    Delete
                </button>
                <button
                    disabled={selectedIndexes.length === 0}
                    aria-label='add mark'
                    className={markAllForRedactionButtonClass}
                    onClick={onMarkAllForRedaction}
                >
                    Add Mark
                </button>
            </div>
            <div className="redaction-search-counter-controls">
                {(searchStatus === SearchStatus['SEARCH_IN_PROGRESS'] || searchStatus === SearchStatus['NLP_LOAD_IN_PROGRESS']) && (
                    <div style={{width: '100%'}}>
                        <SearchProgressBar/>
                    </div>)}
                {shouldShowResultsCounterOptions && (
                    <>
                        <div className="redaction-search-results-counter">
                            <span>Search Results</span> ({AnnotationFilterHelpers.getCounter(redactionSearchResults)})
                        </div>
                        <button
                            onClick={selectAllResults}
                            disabled={isEmptyList}
                            aria-label='Select all'
                        >
                            Select All
                        </button>
                        <button
                            disabled={isEmptyList}
                            onClick={unselectAllResults}
                            aria-label='Unselect all'
                        >
                            Unselect all
                        </button>
                    </>)}
            </div>
            <BasicAnnotationFilter pageRangeChangeCallBack={filterPageRangeOnChange}
                              searchPhraseChangeCallBack={searchPhraseOnChange}
                              categoryChangeCallBack={categoryOnChange}
                              categories={AnnotationFilterHelpers.getCategories(redactionSearchResults)}/>
            <div className={resultsContainerClass} role="list">
                {searchStatus === SearchStatus['SEARCH_NOT_INITIATED'] && renderStartSearch()}
                {(searchStatus === SearchStatus['SEARCH_IN_PROGRESS'] && isEmptyList && isProcessingRedactionResults) && renderSearchInProgress()}
                {searchStatus === SearchStatus['SEARCH_DONE'] && isEmptyList && !isProcessingRedactionResults && noResults}
                {(searchStatus === SearchStatus['SEARCH_IN_PROGRESS'] || searchStatus === SearchStatus['SEARCH_DONE']) && renderSearchResults()}
            </div>
            <div className="redaction-search-panel-controls">
                <button
                    onClick={onCancelHandler}
                    aria-label='cancel'
                    className="cancel"
                >
                    Cancel
                </button>
                <button
                    disabled={selectedIndexes.length === 0}
                    aria-label='delete'
                    className={deleteAllSelectedClass}
                    onClick={onDeleteSelectedResults}
                >
                    Delete
                </button>
                <button
                    disabled={selectedIndexes.length === 0}
                    aria-label='add mark'
                    className={markAllForRedactionButtonClass}
                    onClick={onMarkAllForRedaction}
                >
                    Add Mark
                </button>
            </div>
        </>
    );
}

export default RedactionSearchResults;
